<template>
  <div class="app-mall-order container">
    <div class="content ex padding-20-0">
      <!-- <el-tabs v-model="orderType" @tab-click="toQuery">
      <el-tab-pane name="purchase" label="销售订单">-->
      <div class="head-container">
        <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="销售开始日期" end-placeholder="销售结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" style="width: 260px;" @change="toQuery" />
        <el-select v-model="query.status" filterable clearable placeholder="状态" class="filter-item" style="width: 120px" @change="toQuery">
          <el-option v-for="item in status" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
        <el-select v-model="query.payStatus" filterable clearable placeholder="收款状态" class="filter-item" style="width: 120px" @change="toQuery">
          <el-option v-for="(v,k) in payStatus" :key="k" :label="v" :value="k" />
        </el-select>
        <el-select v-model="query.sendNotice" filterable clearable placeholder="上报状态" class="filter-item" style="width: 120px;" @change="toQuery">
          <el-option label="已上报" :value="true" />
          <el-option label="未上报" :value="false" />
        </el-select>
        <el-input v-model="query.keywords" class="filter-item" :maxlength="20" placeholder="销售单号/合同号/电话号码搜索" @keypress.enter.native="toQuery" style="width: 220px;" />
        <el-input v-model="query.address" class="filter-item" :maxlength="20" placeholder="地址搜索" @keypress.enter.native="toQuery" style="width: 180px;" />
        <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      </div>

      <el-table v-loading="loading" row-key="id" :data="data" border style="margin-top: 10px;">
        <el-table-column prop="formCode" label="销售单号" width="140" />
        <el-table-column prop="realTotalMoney" label="总金额" width="120" align="right" :formatter="$price" />
        <el-table-column label="已收款" width="120" align="right">
          <template slot-scope="scope">
            <span :class="scope.row.payAmount >= scope.row.realTotalMoney ? 'fc-s' : ''">{{$price(scope.row.payAmount)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="待收款" width="120" align="right">
          <template slot-scope="scope">
            <span :class="scope.row.payAmount < scope.row.realTotalMoney ? 'fc-e' : ''">{{$price(scope.row.realTotalMoney-scope.row.payAmount)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="customerName" label="客户名称" width="100" />
        <el-table-column prop="customerPhone" label="客户电话" width="120" show-overflow-tooltip />
        <el-table-column prop="info" label="备注" min-width="160" show-overflow-tooltip />
        <el-table-column label="状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="已上报" width="60" align="center">
          <template slot-scope="scope">
            <i class="fa fa-check" v-if="scope.row.sendNotice"></i>
          </template>
        </el-table-column>

        <el-table-column width="140px" align="center">
          <div class="row-commands" slot-scope="scope">
            <el-button size="mini" type="text" @click="detail(scope.row)">详细</el-button>
            <el-button size="mini" v-permission="['RETAILMANAGE_ALL', 'RETAILMANAGE_EDIT']" type="text" @click="edit(scope.row)" v-if="scope.row.status === 'input' || scope.row.status === 'callback'">编辑</el-button>
            <el-button size="mini" v-permission="['ADMIN','RETAILMANAGE_ALL','RETAILMANAGE_DELETE']" class="danger" type="text" @click="del(scope.row)" v-if="scope.row.status === 'input'">删除</el-button>
          </div>
        </el-table-column>
      </el-table>

      <el-pagination class="padding-10-0" :total="total" :page-size="size" :current-page="page + 1" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
      <!-- </el-tab-pane>
      </el-tabs>-->
    </div>
    <retail-form ref="retailForm" :payable="false" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import RetailForm from "../../order/form";
import { del } from "@/api/shopOrder";

export default {
  mixins: [initData],
  components: { RetailForm },
  props: {
    shopId: Number | String
  },
  data() {
    return {
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      payStatus: {
        all: "全部已收",
        part: "部分已收",
        zero: "待收款"
      },
      status: {
        input: { key: "input", label: "录入状态", type: "info" },
        submit: { key: "submit", label: "提交状态", type: "" },
        auditing: { key: "auditing", label: "审核中", type: "" },
        pass: { key: "pass", label: "审核通过", type: "success" },
        fail: { key: "fail", label: "审批未通过", type: "danger" },
        callback: { key: "callback", label: "退回修改", type: "info" },
        unsend: { key: "unsend", label: "待发货", type: "info" },
        sended: { key: "sended", label: "已发货", type: "success" },
        sendPart: { key: "sendPart", label: "部分已发货", type: "success" },
        cancel: { key: "cancel", label: "已取消", type: "info" },
        closed: { key: "closed", label: "已关闭", type: "info" },
        closedPart: { key: "closedPart", label: "部分已关闭", type: "info" }
      },
      query: {
        dateRange: null,
        status: null,
        payStatus: null,
        keywords: null,
        address: null,
        sendNotice: null
      }
    };
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/shop/form";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      let o = {
        sort: "formCode,desc",
        shopId: this.shopId
      };
      this.params = Object.assign(o, query);
      return true;
    },
    edit(data) {
      this.$refs.retailForm && this.$refs.retailForm.resetForm(data);
    },
    detail(row) {
      this.$router.push({
        path: "/mall/retail/order/" + row.id
      });
    },
    del(row) {
      this.$confirm(
        `您确定要删除订单编号为【${row.formCode}】的销售订单吗？`,
        "操作确认",
        {
          type: "warning"
        }
      ).then(_ => {
        del(row.id).then(res => {
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除销售订单成功",
            type: "success",
            duration: 2500
          });
        });
      });
    },
    print(id) {}
  },
  activated() {
    if (!window._mall_handleback) {
      this.init();
    }
    window._mall_handleback = false;
  }
};
</script>